import React from 'react';
import './App.css';
import Navbar from './components/Navbar';
import Header from './components/Header';
import About from './components/About';
import Pray from './components/Pray';
import Photos from './components/Photos';
import Contact from './components/Contact';
import { Element } from 'react-scroll';

function App() {
  return (
    <div className="App">
      <Navbar />
      
      <Element name="header">
        <Header />
      </Element>

      <Element name="about">
        <About />
      </Element>

      <Element name="pray">
        <Pray />
      </Element>
      
      <Element name="photos">
        <Photos />
      </Element>
      
      <Element name="contact">
        <Contact />
      </Element>
    </div>
  );
}

export default App;
