import React from 'react';
import { Link } from 'react-scroll';
import '../styles/Navbar.css'

const Navbar: React.FC = () => {
  return (
    <nav>
      <ul className="nav-bar">
        <li>
          <Link to="header" smooth={true} duration={500}>Home</Link>
        </li>
        <li>
          <Link to="about" smooth={true} duration={500}>Farské Oznamy</Link>
        </li>
        <li>
          <Link to="pray" smooth={true} duration={500}>Modlitby</Link>
        </li>
        <li>
          <Link to="photos" smooth={true} duration={500}>Fotky</Link>
        </li>
        <li>
          <Link to="contact" smooth={true} duration={500}>Kontatkt</Link>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
