import React from 'react';

const About: React.FC = () => {
  return (
    <section id="about">
      <h2>Fotky z akcii</h2>
      <p>Momentalne nemame ziadne fotky</p>
    </section>
  );
};

export default About;
