import React from 'react';
import coverimg from "../assets/images/background/Farnosť Hontianska Vrbica.jpg";
import '../styles/Header.css'; // Import CSS súboru

const Header: React.FC = () => {
  return (
    <section id="header" className="header-section">
      <img 
        src={coverimg} 
        alt="Fara Hontianska Vrbica" 
        className="cover-img"
      />
    </section>
  );
};

export default Header;
