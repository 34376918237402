const dataPrays = [
  {
    id: 'ID1',
    src: require('../assets/images/modlitby/id1.png'),
    alt: 'Radostný ruženec',
    extras: [
      { id: 'ID101', src: require('../assets/images/modlitby/id101.png') },
      { id: 'ID102', src: require('../assets/images/modlitby/id102.png') },
      { id: 'ID103', src: require('../assets/images/modlitby/id103.png') },
      { id: 'ID104', src: require('../assets/images/modlitby/id104.png') },
    ],
  },
  {
    id: 'ID2',
    src: require('../assets/images/modlitby/id2.png'),
    alt: 'Ruženec svetla',
    extras: [
      { id: 'ID201', src: require('../assets/images/modlitby/id201.png') },
      { id: 'ID202', src: require('../assets/images/modlitby/id202.png') },
      { id: 'ID203', src: require('../assets/images/modlitby/id203.png') },
      { id: 'ID204', src: require('../assets/images/modlitby/id204.png') },
    ],
  },
  {
    id: 'ID3',
    src: require('../assets/images/modlitby/id3.png'),
    alt: 'Bolestný ruženec',
    extras: [
      { id: 'ID301', src: require('../assets/images/modlitby/id301.png') },
    ],
  },
  {
    id: 'ID4',
    src: require('../assets/images/modlitby/id4.png'),
    alt: 'Slávnostný ruženec',
    extras: [
      { id: 'ID401', src: require('../assets/images/modlitby/id401.png') },
    ],
  },
];

export default dataPrays;
